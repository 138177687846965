import { ARTSTORE_PUBSUB_ENUMS } from '../../constants';

const defaultPopupOptions = {
  width: '0%',
  height: '100%',
  position: {
    origin: 'FIXED',
    placement: 'CENTER',
  },
  theme: 'BARE',
};

const openPopup = (openPersistentPopup, compId, url, options) => {
  openPersistentPopup(url, options, compId);
};

const getArtStoreApi = (pubSub, openPersistentPopup, compId, setProps) => {
  const sendOOImessage = (eventName, data, isPersistent) =>
    pubSub.publish(
      ARTSTORE_PUBSUB_ENUMS.OOI_MESSAGE,
      {
        type: eventName,
        payload: data,
      },
      isPersistent,
    );
  return {
    initCart: (connectedProviders, styleParams) => {
      openPopup(
        openPersistentPopup,
        compId,
        'https://progallery.wix.com/cart-form.html?isStore=true',
        defaultPopupOptions,
      );
      pubSub.subscribe(
        ARTSTORE_PUBSUB_ENUMS.REFRESH_WORKER_DATA,
        data => {
          setProps({
            storeWorkerData: data.data,
          });
        },
        true,
      );
      pubSub.publish(
        ARTSTORE_PUBSUB_ENUMS.USE_OOI_ARTSTORE,
        { isOOI: true, connectedProviders },
        true,
      );
      pubSub.publish(
        ARTSTORE_PUBSUB_ENUMS.GET_WORKER_DATA,
        { styleParams },
        true,
      );
      pubSub.subscribe(ARTSTORE_PUBSUB_ENUMS.CART_ITEMS_CHANGE, data => {
        setProps({
          cartItems: data.data.cartItems,
          cartItemsCount: data.data.cartItemsCount,
        });
      });
      pubSub.subscribe(ARTSTORE_PUBSUB_ENUMS.SHOW_OOI_CART, () => {
        setProps({
          showCart: true,
        });
      });
    },
    openPopup: (url, _options) => {
      const options = { ...defaultPopupOptions, ..._options };
      openPopup(openPersistentPopup, compId, url, options);
    },
    refreshCartItems: connectedProviderId =>
      sendOOImessage(
        ARTSTORE_PUBSUB_ENUMS.REFRESH_OOI_CART,
        { connectedProviderId },
        true,
      ),
    updateConnectedProviders: connectedProviders =>
      pubSub.publish(
        ARTSTORE_PUBSUB_ENUMS.UPDATE_CONNECTED_PROVIDERS,
        { connectedProviders },
        true,
      ),
    refreshWorkerData: styleParams =>
      pubSub.publish(
        ARTSTORE_PUBSUB_ENUMS.GET_WORKER_DATA,
        { styleParams },
        true,
      ),
    addToCart: data =>
      sendOOImessage(ARTSTORE_PUBSUB_ENUMS.ADD_TO_CART, data, false),
    openProviderModal: data =>
      sendOOImessage(ARTSTORE_PUBSUB_ENUMS.OPEN_PROVIDER_MODAL, data, false),
    updateCartItem: data =>
      sendOOImessage(ARTSTORE_PUBSUB_ENUMS.UPDATE_CART_ITEM, data, false),
    clearCart: () =>
      sendOOImessage(ARTSTORE_PUBSUB_ENUMS.CLEAR_CART, {}, false),
    removeFromCart: data =>
      sendOOImessage(ARTSTORE_PUBSUB_ENUMS.REMOVE_FROM_CART, data, false),
  };
};

export default getArtStoreApi;
